<template>
    <div class="max-w-9xl mx-auto">
<!-----------------------------------
Start Header Component
------------------------------------>
  <Header ></Header>
<!-----------------------------------
End Header Component
------------------------------------>

<!-----------------------------------
Start Body Component
------------------------------------>
<div >
  <!------------------SEND NOW PAY LATER------------------>
  <div class="bg-blueNew1"  data-aos="fade-up" data-aos-duration="3000">
  <div class="lg:flex bg-blueNew1  mb-16"  data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
    <div class="lg:w-560px lg:ml-40 ml-7 lg:pt-28 pt-14"  data-aos="fade-right"
                                                      data-aos-duration="3000"
                                                      data-aos-easing="ease-in-sine">
      <div class="font-bold lg:text-6xl lg:leading-tight text-white text-5xl leading-none">{{$t('slogan')}}</div>
      <div class="font-normal text-2xl text-white leading-relaxed mt-4">{{$t('but1')}} </div>
      <div class="flex">
          <div class=" mt-9 mr-5 mb-9 cursor-pointer">
            <button class="text-black leading-5 font-semibold text-black bg-grayNew1 top rounded px-10 py-4"><a class="top" href="https://shop.tagango.com/?i=1"> Je veux ma carte</a></button>
          </div>
          <div class="w-10 h-9 mt-7"><img :src="images.indication" alt="indication" original></div>
        </div> 
        <div id="logos-home-page">
	<div class="slider">
		<div class="slide-track">
			<div class="slide w-232px"><img src="../../assets/images/d1.png" alt="" srcset=""></div>
			<div class="slide w-232px"><img src="../../assets/images/d2.png" alt="" srcset=""></div>
			<div class="slide w-232px"><img src="../../assets/images/d3.png" alt="" srcset=""></div>
      <div class="slide w-232px"><img src="../../assets/images/d4.png" alt="" srcset=""></div>
		</div>
	</div>
</div>
    </div>
    <div class="mt-8"  data-aos="fade-left" data-aos-duration="3000" data-aos-easing="ease-in-sine">
      <div class="lg:w-409px img-top w-screen" data-aos="zoom-in-up" data-aos-duration="3000">
        <video class=" p-2 pl-6 lg:w-609px" autoplay loop muted>
          <source src="../../assets/video/video0.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <!--<Autoplay></Autoplay>-->
  <div class="owl-item active" style="text-align: left; display: flex; padding: 10px 0 10px 10px;"></div>
</div>

  <!------------------FORM COMPONENT----------------->
    <Form></Form>
    <Scroll></Scroll>

    <Processs></Processs>
  
  <!------------------HOW IT WORK ?---------------
  <div id="howitwork"  class="flex justify-center mb-20" data-aos="zoom-in" data-aos-duration="2000">
    <div class="w-9 h-9"><img :src="images.how" alt="how" original></div>
    <div class="font-bold text-5xl leading-10 mt-8">{{$t('how')}}</div>
  </div>
  <div class=" lg:flex justify-between lg:space-x-24 lg:mx-40 mx-5 lg:mb-28 mb-14">
    <div  class="w-1/4" data-aos="zoom-in-up" data-aos-duration="1500">
      <div class="flex justify-center"><div class="flex justify-center w-16 h-16 pt-3 border border-solid border-partnerColor rounded-full mt-9 mb-9 text-blueNew1 font-normal text-4xl leading-1">1</div></div>
      <div class="font-medium text-2xl leading-10 text-center mb-6">{{$t('howT1')}}</div>
      <div class=" font-normal text-base leading-7 text-center">{{$t('howP1')}}</div>
    </div>
    <div class="w-1/4" data-aos="zoom-in-up" data-aos-duration="1000">
      <div class="flex justify-center"><div class="flex justify-center w-16 h-16 pt-3 border border-solid border-partnerColor rounded-full mt-9 mb-9 text-blueNew1 font-normal text-4xl leading-1">2</div></div>
      <div class="font-medium text-2xl leading-10 text-center mb-6">{{$t('howT2')}}</div>
      <div class=" font-normal text-base leading-7 text-center">{{$t('howP2')}}</div>
    </div>
    <div class="w-1/4" data-aos="zoom-in-up" data-aos-duration="500">
      <div class="flex justify-center"><div class="flex justify-center w-16 h-16 pt-3 border border-solid border-partnerColor rounded-full mt-9 mb-9 text-blueNew1 font-normal text-4xl leading-1">3</div></div>
      <div class="font-medium text-2xl leading-10 text-center mb-6">{{$t('howT3')}}</div>
      <div class=" font-normal text-base leading-7 text-center">{{$t('howP3')}}</div>
    </div>
  </div> --->
  <!------------------SHARE SMILE------------------>
  
  <!--<chooseUs></chooseUs>-->
  <lottie-animation
    path="../../assets/animation/animation.json"
/>
  <!--<div id="products">
    <div  class="lg:flex lg:ml-60 lg:mr-10 lg:space-x-24 mr-7">
      <div  data-aos="fade-right" data-aos-duration="3000"  class="lg:w-560px img-mask"><img :src="images.girl" alt="girl" original></div>
      <div  data-aos="fade-left" data-aos-duration="3000"  class="space-y-6 lg:mt-8 ml-7 mb-5 mr-7">
        <div class=""><img :src="images.smile" class="" alt="smile" width="38" height="38" original></div>
        <div class="lg:w-520px font-bold text-5xl leading-tight">{{$t('shareSmileT1')}}</div>
        <div class="lg:w-400px font-normal text-base leading-7">{{$t('shareSmileP1')}}</div>
      </div>
    </div>
    <div class="lg:flex lg:ml-72 lg:mr-60 lg:space-x-2 ml-7 mr-5">
      <div data-aos="fade-right" data-aos-duration="3000" class="md:block hidden space-y-6 lg:mt-20 mt-10">
        <div class=""><img :src="images.dollar" alt="dollar" width="38" height="38" original></div>
        <div class="w-400px font-bold text-5xl leading-tight">{{$t('shareSmileT2')}}</div>
        <div class="w-96 font-normal text-base leading-7">{{$t('shareSmileP2')}}</div>
      </div>
      <div data-aos="fade-left" data-aos-duration="3000" class="lg:w-540px img-mask"><img :src="images.boy" alt="boy" original></div>
      <div class="lg:hidden space-y-6 mt-6 mb-10">
        <div><img :src="images.dollar" alt="smile" width="38" height="38" original></div>
        <div class="font-bold text-5xl leading-tight">{{$t('shareSmileT2')}}</div>
        <div class="font-normal text-base leading-7">{{$t('shareSmileP2')}}</div>
      </div>
    </div>
  </div>  -->
  <!------------------ANTICIPATE REPAYMENTS-------------
  <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class="lg:mx-40 mx-7 rounded-2xl bg-blueNew1 lg:my-40 my-14 mt-10 lg:pb-4 pb-10">
    <div class="flex justify-between"> 
      <div class="mt-5"><img :src="images.abstract1" alt="abstract1" width="176" height="240" original></div>
      <div class="text-center">
        <div class="flex justify-center"><img class="pt-11 mb-3" :src="images.flour" alt="flour" width="80" height="80" original></div>
        <div class=" text-whiteNew1 font-medium text-3xl leading-10 mb-4">{{$t('anticipateT')}}</div>
        <div class=" text-whiteNew1 font-normal text-base leading-7 mb-10">{{$t('anticipateP')}}</div>
        <div class="flex justify-center">
          <button class="text-white bg-blueNew1 font-bold text-base leading-5 border rounded px-10 py-4 cursor-pointer"><a href="#">Démarrer</a></button>
        </div>
      </div>
      <div data-aos="fade-left" data-aos-duration="3000" data-aos-easing="ease-in-sine" class="lg:mt-24 mt-96"><img :src="images.abstract2" alt="abstract2" width="266" height="305" original></div>
    </div>
  </div>  ----->
     <!------------------Telechargements------------------>
      <div class="lg:flex bg-blueNew1 bg-cover bg-center "   id="howitwork" style="background-image: url('../../assets/images/rod.png');">       
    <div class=" lg:ml-40 ml-7 w-1/3 zaza ">
     <!-- <h1 class="text-blueNew1">{{$t('title1')}}</h1> -->
    <br>
    <div class="font-bold lg:text-4xl text-white lg:leading-tight text-5xl leading-none">{{$t('slogan1')}} </div>
    <div class="font-normal text-xl text-white leading-relaxed mt-4 mb-16">Tag'n Go est une carte de visite qui utilise la technologie NFC et QR code. C'est une solution simple et pratique ! L'utiliser est comme un jeu d'enfant. </div>
    <!--<div class="flex">
        <div class=" mt-9 mr-5 mb-9 cursor-pointer" @click="openForm">
          <button class="text-base leading-5 font-semibold  bg-blueNew1 rounded px-10 py-4">{{$t('button')}}</button>
        </div>
        <div class="w-10 h-9 mt-7"><img :src="images.indication" alt="indication" original></div>
      </div>  -->
      <div class="flex mb-12">
        <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4" data-v-74387e92="">
        </div>
      </div>
    </div>
  <div class=" zaza ml-6" >
    <div class=" tp w-200px"><img :src="images.app" alt="app" original></div>
  </div>
    <div class=" zaza ml-6" >
      <div class="">
        <div class=" mr-15 ">
            <div class="lg:w-2/3 mb-6" >
              <div class="flex mt-8  w-12 h-12"><h1 class="  mr-2 text-black font-bold text-2xl">NFC</h1></div>
              <div class="  font-normal text-xl text-white leading-9">{{$t('worldP2')}} <br> Ouvrez la notification.</div>
            </div>
            <div class="lg:w-2/3 mb-6">
              <div class="flex mt-8  w-2/3 h-12"><h1 class="  mr-2 text-black font-bold text-2xl">Qr Code</h1></div>
              <div class=" font-normal text-white text-xl leading-9">
          
                1. Lancez l'appareil photo. <br>
                2. Scannez le QR code. <br>
                3. Ouvrez la notification.</div>
            </div>
          </div>   
      </div>
    </div>
  </div>
   
  <!------------------WE HAVE YOUR BACK------------------>
  <!--<div class="text-center font-bold lg:text-5xl lg:leading-10 lg:mb-36 text-3xl leading-9 mb-8">{{$t('yourBack')}}</div>
  <div class="lg:flex lg:space-x-20 lg:ml-40 lg:mr-36 mx-7 lg:mb-10">
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.world" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('worldT')}}</div>
      <div class=" w-80 font-normal text-xl leading-9">{{$t('worldP')}}</div>
    </div>
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.rate" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('rateT')}}</div>
      <div class="w-80 font-normal text-xl leading-9">{{$t('rateP')}}</div>
    </div>
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.transfer" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('transferT')}}</div>
      <div class="w-80 font-normal text-xl leading-9">{{$t('transferP')}}</div>
    </div>
  </div>
  <div class="lg:flex lg:space-x-20 lg:ml-40 lg:mr-36 mx-7">
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.process" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('processT')}}</div>
      <div class="w-80 font-normal text-xl leading-9">{{$t('processP')}}</div>
    </div>
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.secure" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('secureT')}}</div>
      <div class="w-80 font-normal text-xl leading-9">{{$t('secureP')}}</div>
    </div>
    <div>
      <div class="mt-8 mb-8 w-16 h-16"><img :src="images.support" alt="world" original></div>
      <div class="font-medium text-xl leading-6 mb-4">{{$t('supportT')}}</div>
      <div class="w-80 font-normal text-xl leading-9">{{$t('supportP')}}</div>
    </div>
  </div> -->
  <!--------------------LES COMMANDES ------------------>
 <!--<section class="text-gray-600 body-font overflow-hidden">
  <div id="tarif" class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">N'hésitez plus, choisissez votre carte!</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">Quelque soit votre budget, votre ambition, vous trouverez l'option qui vous convient. Vous ne la trouvez pas sur notre site, alors contactez-nous contact@tagango.com et nos équipes se feront le plaisir de vous accompagner dans votre projet.</p>

    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-blueNew1 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium">Découverte</h2>
          <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Gratuit</h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Création de votre compte
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Personnalisation de votre profil
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>URL personnalisé à partager
          </p>
          <button class="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full bg-blueNew1  focus:outline-none hover:bg-gray-500 rounded" ><a href="https://app-tagang.surge.sh/">Démarrer</a>
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-blueNew1 flex flex-col relative overflow-hidden">
          <span class="bg-green-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium">Professionnel</h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span>15.000 FCFA</span>
            <span class="text-lg ml-1 font-normal text-gray-500">/mo</span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Création de votre compte
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Personnalisation de votre profil
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>URL personnalisé à partager
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>QRCode
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Une carte basique Tag'ngo
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Un tableau de bord
          </p>
          <button class="flex items-center mt-auto text-white bg-blueNew1 border-0 py-2 px-4 w-full focus:outline-none hover:bg-green-600 rounded" ><a href="https://app-tagang.surge.sh/">Créer Votre compte</a>
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="p-4 xl:w-1/3 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium">Entreprise</h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span>25000 FCFA</span>
            <span class="text-lg ml-1 font-normal text-gray-500">/mo</span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Création de votre compte
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Personnalisation de votre profil
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>URL personnalisé à partager
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>QRCode
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Une carte tag'ngo personnalisable
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Un tableau de bord riche
          </p>
          <button class="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded" >Demander un devis
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium">SPECIAL</h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span>$72</span>
            <span class="text-lg ml-1 font-normal text-gray-500">/mo</span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Vexillologist pitchfork
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Tumeric plaid portland
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Hexagon neutra unicorn
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Vexillologist pitchfork
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Mixtape chillwave tumeric
          </p>
          <button class="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Button
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
        </div>
      </div> 
    </div>
  </div>
</section> --> 
  <!------------------OUR PARTNERS---------------
    <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class=" lg:mx-40 px-7 lg:rounded-2xl bg-blueNew1 bg-opacity-10 lg:my-40 mt-10 pb-20">
      <div class="text-5xl leading-10 font-bold text-center py-8">{{$t('partnerT')}}</div>
      <div class="text-base leading-7 text-center lg:mx-60 pb-16">{{$t('partnerP')}}</div>
      <div class="lg:flex lg:space-x-12 lg:space-y-0 space-y-8 lg:mx-36 ">
        <div class="flex justify-center space-x-12 ">
          <div><img :src="images.ecobank" alt="ecobank" width="158" height="77.68" original></div>
          <div><img :src="images.uba" alt="uba" width="358" height="500" original></div>
        </div>
        <div class="flex justify-center space-x-12">
          <div><img :src="images.orange" alt="orange" width="179" height="74" original></div>
          <div><img :src="images.mtn" alt="mtn" width="111" height="74.19" original></div>
        </div>
      </div>
    </div> --->
</div>
<CookieBanner v-if="showBanner" @hideBanner="showBanner = false"/>

<!-----------------------------------
End Body Component
------------------------------------>
<!--<Whatsapp></Whatsapp> -->

<AppButton></AppButton>
<!-----------------------------------
Start Footer Component
------------------------------------>
  <Footer></Footer>
<!-----------------------------------

End Footer Component
------------------------------------>
</div>
</template>

<script>
/*Import Components */
import AppButton from '../../components/AppButton.vue'
import Header from '../../components/header/index.vue';
import Footer from '../../components/footer/index.vue'
import Form from '../../components/form/index.vue'
import Whatsapp from '../../components/whatsapp/index.vue'
import Processs from '../../components/home/process.vue';
import CookieBanner from '../../components/home/CookieBanner.vue'
import chooseUs from '../../components/home/chooseUs.vue'
import Faq from '../../components/home/Faq.vue';
import app from '../../assets/images/telephone.webp'
import Autoplay from '../../components/home/Autoplay.vue'



/*Import Images*/
/*------------------IMAGES SEND NOW PAY LATER------------------*/
import indication from '../../assets/images/img_indication.webp'
import carousel from '../../assets/video/video0.mp4'
import how from '../../assets/images/img_how.webp'
/*------------------IMAGES SHARE SMILE------------------*/
import girl from '../../assets/images/img_girl.webp'
import boy from '../../assets/images/img_boy.jpg'
import smile from '../../assets/images/img_smile.webp'
import dollar from '../../assets/images/img_dollar.webp'
/*------------------IMAGES ANTICIPATE REPAYMENTS------------------*/
import flour from '../../assets/images/img_flour.webp'
import abstract1 from '../../assets/images/img_abstract1.webp'
import abstract2 from '../../assets/images/img_abstract2.webp'
/*------------------IMAGES WE HAVE YOUR BACK-----------------*/
import world from '../../assets/images/nfc.svg'
import rate from '../../assets/images/qrcode.svg'
import transfer from '../../assets/images/img_tansfer.webp'
import process from '../../assets/images/img_process.webp'
import secure from '../../assets/images/img_secure.webp'
import support from '../../assets/images/img_support.webp'
/*------------------IMAGES OUR PARTNERS-----------------*/
import ecobank from '../../assets/images/esmt.webp'
import uba from '../../assets/images/logoss.png'
import mtn from '../../assets/images/heaven.webp'
import orange from '../../assets/images/hotel.webp'
import LottieAnimation from 'lottie-vuejs' // import lottie-vuejs
import Deroul from "../../components/home/Deroul.vue"
var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?9071';
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var options = {
  "enabled":true,
  "chatButtonSetting":{
      "backgroundColor":"#4dc247",
      "ctaText":"Contactez-nous",
      "borderRadius":"25",
      "marginLeft":"0",
      "marginBottom":"50",
      "marginRight":"50",
      "position":"left"
  },
  "brandSetting":{
      "brandName":"Tag'ngo",
      "brandSubTitle":"Réponse en général dans la journée",
      "brandImg":"https://app-tagang.surge.sh/_nuxt/img/5ac3c1c.png",
      "welcomeText":"Bonjours à tous !\nComment puis-je vous aider ?",
      "messageText":"Bonjour, j'ai une question à propos de {{page_link}}",
      "backgroundColor":"#0a5f54",
      "ctaText":"Démarrer la discussion",
      "borderRadius":"25",
      "autoShow":false,
      "phoneNumber":"221775221793"
  }
};
    s.onload = function() {
        CreateWhatsappChatWidget(options);
    };
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);



export default {
  name: "index",

  components: {
      Header,
      Footer,
      Form,
      AppButton,
      Processs,
      LottieAnimation,
      CookieBanner,
    },

  data () {
    return {
      icons: {
      },
      images:{
        indication, carousel,
        how,
        girl, boy, smile, dollar,
        flour, abstract1, abstract2,
        world, rate, transfer, process, secure, support,
        ecobank, uba, mtn, orange, app
      },
      showBanner: !(this.$posthog.has_opted_out_capturing()||this.$posthog.has_opted_in_capturing())
    }
  },
  methods: {
    openForm() {
      document.getElementById("myForm").style.display = "block";
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

 span{
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, #81c141 50%, #81c141 100%);
}
.img-top{
  width: 90%;
  height: 90%;
}
.img-mask {
  -webkit-mask: url("../../assets/images/images-mask.png") center/contain no-repeat;
  max-width: 100%;
  transition: background-color 0.5s !important;
}
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


/*  Styling  */
#logos-home-page .slider {
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#logos-home-page .slider:before,#logos-home-page .slider:after {
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
#logos-home-page .slider:after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

#logos-home-page .slider:before {
		left: 0;
		top: 0;
	}
	#logos-home-page .slide-track {
		animation: scroll 100s linear infinite;
		display: flex;
		width: calc(250px * 5);
	}
	
#logos-home-page div.slide {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.top{
  color: black !important;
}
.tp{
  width: 40%;
  
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .zaza {
    display: block;
    width: auto;
   
  }

}
</style>