<template>
    <button @click="scrollToTop" ref="appButton" class="app-button bg-white">
        <img :src="images.scroll" alt="logo" original>
        </button>
  </template>
  
  <script>
  import { onMounted, ref, onBeforeMount } from "vue";
  import scroll from '../assets/images/sroll.png'
  export default {
    name: "AppButton",
    data () {
    return {
      icons: {
      },
      images:{
        scroll
      },
      
    }
  },
    setup() {
      const appButton = ref();
      const userScroll = () => {
        if (window.scrollY > 0) {
          appButton.value.classList.add("showButton");
          console.log("scrolled");
        } else {
          appButton.value.classList.remove("showButton");
          console.log("top");
        }
      };
      onMounted(() => {
        window.addEventListener("scroll", userScroll);
      });
      onBeforeMount(() => {
        window.removeEventListener("scroll", userScroll);
      });
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
      return { appButton, scrollToTop };
    },
  };
  </script>
  
  <style scoped>
  .app-button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 4px;
    box-shadow: 2px 2px 2px 2px #e9e9e9;
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    display: none;
    right: 40px;
    
  }
  .showButton {
    display: block;
  }
  </style>