<template>
<div class="create-button p-6 h-12 w-1/3 rounded-full text-white text-lg flex justify-center items-center cursor-pointer">
  {{ $t(`${label}`) }}
</div>
</template>

<script>
export default {
  name: 'CreateButton',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/sass/variables";
.create-button  {
background-color: #8bc141;
}
</style>
