// components/CookieBanner.vue
<template>
  <div class="banner">
    <p>
      We use tracking cookies to understand how you use the product 
      and help us improve it.
      Please accept cookies to help us improve.
    </p>
    <button type="button" @click="acceptCookies">Accept cookies</button>
    <button type="button" @click="declineCookies">Decline cookies</button>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  methods: {
    acceptCookies() {
      this.$posthog.opt_in_capturing();
      this.$emit("hideBanner")
    },
    declineCookies() {
      this.$posthog.opt_out_capturing();
      this.$emit("hideBanner")
    }
  }

};
</script>

<style scoped>
.banner {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}
</style>