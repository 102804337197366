<template>
<!------------------FORM FOR SIGN UP------------------>
<div id="myForm">
  <div class="flex justify-center" @submit.prevent="sendEmail">
    <form class="lg:w-400px rounded mx-7 bg-white">
      <div class="flex justify-end pt-3">
        <div class="mr-5 mb-4 w-9 h-9 cursor-pointer" @click="closeForm"><img :src="images.close" alt="close" original></div>
      </div>
      <div class="px-8 pb-6 space-y-3 justify-center">
        <div class="w-80 font-bold text-base leading-7 pb-2">Laissez-nous un message et nous vous recontacterons rapidement</div>
        <div for="name" class="font-medium text-xs leading-4 text-formlabelColor">Nom complet</div>
        <input v-model="name" type="text" id="name" name="name" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="email" class="font-medium text-xs leading-4 text-formlabelColor">{{$t('formL3')}}</div>
        <input v-model="email" id="email" name="email" type="Email" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="phone" class="font-medium text-xs leading-4 text-formlabelColor">{{$t('formL4')}}</div>
        <input v-model="phone" type="text" id="phone" name="phone" class=" w-full h-12 border border-solid rounded pl-3">
        <div for="country" class="font-medium text-xs leading-4 text-formlabelColor">Message</div>
        <textarea v-model="country" name="country" id="country" cols="30" rows="10" class=" w-full h-20  border border-solid rounded pl-3"></textarea>
        <div @click="closeForm" class="pt-2">
          <button :disabled="!formIsValid" class="w-full h-12 text-base leading-5 font-semibold text-white bg-blueNew1 rounded cursor-pointer">Envoyer</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>

/*Import Images*/
/*------------------IMAGES FORM ------------------*/
import emailjs from 'emailjs-com';
import close from '../../assets/images/img_close.webp'

export default {
 
  name: "index",
  data () {
    return {
      icons: {
      },
      images:{
        close
      },
      country: '',
      name: '',
      email: '',
      phone: '',
      isFormValid: false
    }
  },
   watch: {
    name() {
      this.validateForm()
    },
    email() {
      this.validateForm()
    },
    phone () {
      this.validateForm()
    },
    country () {
      this.validateForm()
    }
  },
   computed: {
    formIsValid() {
      return this.name && this.email && this.phone && this.country
    }
  },
  methods: {
    validateForm() {
      this.isFormValid = this.name !== '' && this.email !== '' && this.phone !== '' && this.country
    },
    closeForm() {
      document.getElementById("myForm").style.display = "none";
    },
    
    sendEmail(e) {
            if (this.isFormValid) {
      try {
        emailjs.sendForm('service_d8pfspc', 'template_lq1ru33', e.target,
        'PPiIi_t78k8srRn0Y', {
          country: this.country,
          name: this.name,
          email: this.email,
          phone: this.phone
        })

      } catch(error) {
          console.log({error})
      } }
      // Reset form field
      this.name = ''
      this.email = ''
      this.phone = ''
      this.country= ''
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";

/* The popup form - hidden by default */
#myForm {
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    position: fixed;
    display: none;
    background-color: black;
}

form{
  position: fixed;
    top: 10%;
}
</style>



